h2.register-title {
  line-height: 1.2;
  text-align: center;
  font-size: 2.5rem;

  display: block;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 4%;
}

.register_page {
  margin: 0 auto;
  min-width: 100vw;
  text-align: center;
  display: inline-block;
}