.new-mood {
  width: 100%;
  display: block;
  text-align: center;
}

.new-mood-wrap {
  position: relative;
  margin: 0 auto;
  width: 70%;
  background-color: #414B60;
  border-radius: 20px;
}

/* Input and text area */
div.form-section>input, 
div.form-section>textarea  {
  border-radius: 5px;
  padding: 10px;
  line-height: 1.2rem;
  font-size: 1.2rem;
}

div.form-section>textarea {
  font-family: 'Arial';
  padding: 5px 0;
}

.new-mood-title {
  font-size: 3rem;
  margin: 40px 0;
  padding-top: 20px;
}

#record-mood {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

#time-mood {
  width: 7%;
}

/* Textarea box css */
div.form-section>label.label-title {
  font-size: 1.1rem;
  display: block;
  margin: 1.2rem 0 .3rem 0;
}

.new-mood-content {
  height: 12rem;
  width: 90%;
  resize: none;
}

/* Radio buttons styling */
.form-section .radio-title {
  font-size: 1.1rem;
  display: block;
  margin-top: 1.2rem;
}

div.radio-form {
  margin: 0 15%;
}

label[for="mood-type"]>span {
  font-size: 1.5rem;
}

.mood-type-explanation {
  margin: .5rem 0 2rem 0;
  text-align: left;
}

input[type="radio"] {
  border: 0px;
  width: 40px;
  height: 1.5em;
}

/* Buttons */
.new-mood-btn-container {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
}

.new-mood button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 27%;
  height: 30px;
  background-color: rgb(0, 29, 54);
  border-radius: 25px;
  border: none;
  cursor: pointer;
  margin: 0 2%;

  letter-spacing: 2px;
  font-family: 'Arial';
  line-height: 1.2;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
}

.new-mood button:hover {
  background-color: #1A5486;
}

@media screen and (min-width: 968px) {
  .new-mood-wrap {
    width: 650px;
  }
}