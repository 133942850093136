.landing-container {
  display: block;
  text-align: center;
}

.landing-main {
  padding-top: 20px;
  font-size: 4rem;
  font-weight: 600;
}

.landing-wrap {
  margin: 0 100px 0 100px;
  text-align: center;
}

.landing-wrap section {
  padding-top: 25px;
}

.landing-text {
  padding: 20px 0 20px 0;
  line-height: 1.2;
}

.landing-3 {
  font-size: 1.8rem;
}

header>h2 {
  font-size: 1.2rem;
}

img.moods {
  width: 65%;
  height: 60%;
  padding: 10px 0;
}

@media screen and (min-width: 968px) {
  .landing-container section, 
  .landing-container header {
    width: 650px;
    margin: 0 auto;
  }
}