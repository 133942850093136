.misc-chart {
  position: relative;
  margin: 0 auto;
  width: 70%;
  background-color: #414B60;
  border-radius: 20px;
}

@media screen and (min-width: 968px) {
  .misc-chart {
    width: 650px;
  }
}