.mood-page {
  margin: 0 auto;
  min-width: 100vw;
  display: flex;
  flex-wrap: wrap;
}

.search-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

/* Title of the 'My Moods' tab page */
.mood-page>h1 {
  display: block;
  font-size: 3rem;
  padding: 40px 0 10px 0;
  margin: 0 auto;
}

/* Search title and input field */
h3.search-title {
  font-size: 1.1rem;
  font-style: italic;
  padding-bottom: 0;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 2;
}

input.mood-page-input {
  display: block;
  padding: 10px;
  margin-left: 20px;
  font-size: 15px;
  width: 15rem;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
}

input.mood-page-input::placeholder {
  color: black;
}